<template>
  <div>
    <TitleWrapper :filter-option="false" title="SLY" tooltip-title="SLY" :display-breadcrumb="true">
      <template v-slot:filterItems>
        <h2 v-i18n="result" class="mb-3 text-text-color font-roboto font-normal text-xl">Search</h2>
      </template>
    </TitleWrapper>
    <template v-if="isLoading">
      <Loader class="mt-20 mb-40" :content="true" />
    </template>
    <div v-else-if="!isLoading && isEmpty(salaryList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-for="(head, index) in tableHead" :key="index">
            <span v-i18n="dashboard">
              {{ head }}
            </span>
          </TH>
          <TH>Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(salary, index) in salaryList" :key="index">
          <TD>
            <span
              class="cursor-pointer text-primary-purple-600"
              @click="redirectToDetailPage(salary)"
            >
              {{ salary.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD>
            <UiBadgeDisplay
              :options="[salary.staff_user]"
              :image="true"
              label="full_name"
              class="pl-8"
            />
          </TD>
          <TD>
            {{ salary.base_salary || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ salary.gross_salary || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ salary.net_salary || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            <TableAction
              :idx="index"
              :current-user="salary"
              :action-list="[
                { name: 'Edit' },
                { name: 'Delete' },
                { name: !salary.published_at ? 'Publish' : 'Un Publish' },
              ]"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="totalSalaryCount > 10"
      v-show="!isLoading"
      :key="`ForceRender${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(totalSalaryCount, filteredRecordLimit)"
      @filterRecord="_getSalries"
    />

    <SalaryTemplateModal
      v-if="showModal"
      :is-salary-type="true"
      :edit="editSalaryObject"
      @update="getNewData"
    />

    <UiConfirmationComponent
      heading="Delete Salary"
      button-text="Delete"
      :modal="deleteModelShow"
      :is-loading="isModalLoading"
      @confirm="deleteSalaryById"
      @cancel="toogleDeleteModel"
    />

    <UiConfirmationComponent
      :heading="`${getPublishUnpublishText} Salary`"
      :is-loading="isModalLoading"
      :message="getPublishUnpublishMessage"
      :button-text="getPublishUnpublishText"
      :modal="confirmModalShow"
      @confirm="publishUnpublishById"
      @cancel="toogleConfirmModel"
    >
      <div>
        <slot name="body">
          <div class="py-3 px-6 text-xs md:text-base">
            <div class="flex lg:gap-3 flex-wrap">
              <div v-for="(item, idx) in salaryDetails" :key="idx" class="w-full">
                <div
                  v-if="item.name === 'Gross Salary'"
                  class="border-t border-primary-grey pb-3"
                ></div>
                <div class="flex">
                  <div v-i18n="title" class="font-bold">{{ item.name }}</div>
                  <div
                    class="ml-auto"
                    :class="[
                      item.name === 'Gross Salary'
                        ? 'text-primary-green'
                        : 'text-primary-grey-light',
                    ]"
                  >
                    {{ item.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </slot>
      </div>
    </UiConfirmationComponent>
  </div>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import generalMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { getTimeFromDateWithUTC } from '@utils/moment.util'
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import SalaryTemplateModal from '@components/salary-manager/SalaryTemplateModal.vue'
import TableAction from '@components/TableAction.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import UiBadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import isEmpty from 'lodash/isEmpty'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    Loader,
    TitleWrapper,
    Pagination,
    SalaryTemplateModal,
    TableAction,
    UiBadgeDisplay,
    UiConfirmationComponent: () => import('@src/components/UiElements/UIConfirmationModal.vue'),
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixin, scrollMixin],
  data() {
    return {
      forceRender: 0,
      result: 'result',
      title: 'title',
      dashboard: 'dashboard',
      deleteModelShow: false,
      confirmModalShow: false,
      totalSalaryCount: 0,
      tableHead: ['Salary Title', 'Staff', 'Base Salary', 'Gross Salary', 'Net Salary'],
      salaryList: [],
      noRecord: false,
      filteredRecordLimit: 10,
      editSalaryObject: null,
      currentSalaryObject: { staff_user: {}, published_at: null },
      salaryDetails: [],
      GENERAL_CONSTANTS,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.salary.isLoading,
      isModalLoading: (state) => state.salary.isModalLoading,
      showModal: (state) => state.salary.showModal,
      getDeleteMessage() {
        return `Are you sure you want to Delete ${this.getFullName(this.currentSalaryObject)}`
      },
      getPublishUnpublishMessage() {
        return `Are you sure you want to ${this.getPublishUnpublishText} ${this.getFullName(
          this.currentSalaryObject,
        )} Salary`
      },
      getPublishUnpublishText() {
        if (this.currentSalaryObject.published_at) return 'Unpublish'
        return 'Publish'
      },
    }),
  },
  watch: {
    showModal: {
      handler(value) {
        if (!value) this.editSalaryObject = null
      },
    },
  },
  created() {
    this.setRightbar()
    this._getSalries()
  },
  methods: {
    isEmpty,
    redirectToDetailPage(salary) {
      this.$router?.push({
        name: 'user-salary-detail',
        params: {
          id: salary.id,
        },
      })
    },

    getNewData() {
      this._getSalries()
      this.editSalaryObject = null
      this.forceRender++
    },

    deleteSalaryById() {
      this.delSalary(this.currentSalaryObject.id)
    },

    publishUnpublishById() {
      if (this.currentSalaryObject.published_at) {
        this._unPublishSalary(this.currentSalaryObject)
      } else {
        this._publishSalary(this.currentSalaryObject)
      }
    },

    async delSalary(id) {
      const [res, err] = await this.deleteSalary(id)
      this.toogleDeleteModel()
      this.getNewData()
    },

    async _getSalries(range) {
      const paginationLimit = {
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      const [res, err] = await this.getSalaries(paginationLimit)
      this.salaryList = res.data.records.map((rec) => {
        rec.staff_user.full_name = this.getFullName(rec)
        if (rec.extra_working_unit && rec.extra_working_unit_rate)
          rec.extra_working = [
            { amount: rec.extra_working_unit_rate, unit_type: rec.extra_working_unit },
          ]
        return rec
      })
      this.totalSalaryCount = res.data.meta.total_records
    },

    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
    },

    toogleConfirmModel() {
      this.confirmModalShow = !this.confirmModalShow
    },

    typeAction(action, salary, idx) {
      switch (action) {
        case 'Edit':
          this.editSalaryObject = salary
          this.setModal(true)
          break
        case 'Delete':
          this.currentSalaryObject = salary
          this.toogleDeleteModel()
          break
        case 'Publish': {
          this.calculateSalaryDetails(salary)

          this.currentSalaryObject = salary
          this.toogleConfirmModel()
          break
        }
        case 'Un Publish': {
          this.calculateSalaryDetails(salary)

          this.toogleConfirmModel()
          this.currentSalaryObject = salary
          break
        }
      }
    },

    async _publishSalary(salary) {
      const [res, err] = await this.publishSalary(salary.id)
      if (res) {
        this.toogleConfirmModel()
        this.getNewData()
      }
    },

    async _unPublishSalary(salary) {
      const [res, err] = await this.unPublishSalary(salary.id)
      if (res) {
        this.toogleConfirmModel()
        this.getNewData()
      }
    },

    setRightbar() {
      this.setRightbarContent({
        header: {
          title: 'Salary',
          buttons: [
            {
              title: 'Add Salary',
              classes: ['primary-button-right'],
              action: { name: 'salary/showModal' },
            },
          ],
        },
      })
    },

    calculateSalaryDetails(salaryObject) {
      this.salaryDetails = []

      let totalAllowance = salaryObject.salaries_allowances.reduce((acc, salary) => {
        return acc + salary.amount
      }, 0)
      let totalBonuses = salaryObject.salaries_bonuses.reduce((acc, salary) => {
        return acc + salary.amount
      }, 0)
      let totalDeduction = salaryObject.salaries_deductions.reduce((acc, salary) => {
        return acc + salary.amount
      }, 0)

      this.salaryDetails.push(
        { name: 'Base Salary', value: salaryObject.base_salary },
        { name: 'Total Allowance', value: totalAllowance },
        { name: 'Total Deduction', value: totalDeduction },
        { name: 'Total Bonuses', value: totalBonuses },
        { name: 'Extra Working Amount', value: salaryObject.extra_working_amount },
        { name: 'Gross Salary', value: salaryObject.gross_salary },
      )
    },

    getFullName(salaryObject) {
      return `${salaryObject.staff_user.first_name} ${salaryObject.staff_user.last_name}`
    },

    ...mapActions('salary', [
      'getSalaries',
      'setModal',
      'deleteSalary',
      'publishSalary',
      'unPublishSalary',
    ]),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.widthItem {
  min-width: 50%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>
d
